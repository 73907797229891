<template>
    <master-panel dark>
        <template v-slot:body>
            <v-row>
                <v-col cols="12" class="mt-3 title">
                    Hola {{ accountName }}.
                    <br>
                    Bienvenida a tu sistema de gestión de cirugías.
                </v-col>
                <v-col cols="12" class="subtitle">
                    Debes cargar todos los resultados de tus exámenes que fueron solicitados
                    por el especialista y una vez cargados, recibirás la respuesta del equipo médico.
                </v-col>
                <v-col cols="6" class="text-center">
                    <v-btn color="secondary" x-large class="btn-container" @click="onClickExams" depressed>
                        <v-icon x-large v-text="'$exams'"/>
                    </v-btn>
                    <div class="btn-text">
                        Cargar tus exámenes
                    </div>
                </v-col>
                <v-col cols="6" class="text-center" v-if="medicalResponses">
                    <v-btn color="ternary" x-large class="btn-container" @click="onClickResponse" depressed>
                        <v-icon x-large v-text="'$heart_signal'"/>
                    </v-btn>
                    <div class="btn-text">
                        Respuesta médica
                    </div>
                    <response-dialog ref="response"/>
                </v-col>
                <v-col :cols="medicalResponses ? 12 : 6" class="text-center">
                    <v-btn color="highlight" dark x-large class="btn-container" @click="onClickOrders" depressed>
                        <v-icon x-large v-text="'$laboratory'"/>
                    </v-btn>
                    <div class="btn-text">
                        Ordenes y Convenios
                    </div>
                </v-col>
            </v-row>
        </template>
        <template v-slot:buttons>
            <logout-button color="#FFFFFF"/>
        </template>
    </master-panel>
</template>

<script>

import MasterPanel from "@/components/layouts/MasterPanel";
import LogoutButton from "@/components/common/LogoutButton";
import ResponseDialog from "@/components/client/ResponseDialog";
import httpService from "@/services/httpService";

export default {
    components: {ResponseDialog, LogoutButton, MasterPanel},
    data() {
        return {
            medicalResponses: null
        }
    },
    computed: {
        accountName(vm = this) {
            return vm.currentUser?.name || ''
        },
    },
    async mounted() {
        const vm = this
        await vm.loadMedicalResponse()
    },
    methods: {
        async loadMedicalResponse() {
            const vm = this
            vm.loading = true
            let response = await httpService.get(`client/${vm.currentUser.id}/medical-responses`)
            if (response.data.medical_responses.length > 0)
                vm.medicalResponses = {...vm.currentUser, confirmed_hours: response.data.medical_responses}
            vm.loading = false
        },
        onClickExams() {
            const vm = this
            vm.goTo({name: 'client.exams'})
        },
        onClickResponse() {
            const vm = this
            vm.$refs.response.setClient(vm.medicalResponses)
            vm.$refs.response.show()
        },
        onClickOrders() {
            const vm = this
            vm.goTo({name: 'client.orders'})
        }
    },
}
</script>

<style scoped>

.title {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
}

.subtitle {
    color: #FFFFFF;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 40px;
}

.btn-container {
    border-radius: 12px;
}

.btn-text {
    color: #FFFFFF;
    font-size: 14px;
    line-height: 18px;
    margin-top: 8px;
}

</style>
